import axios from "axios";
import { store } from "../../index";
import { IRealmLoginConfiguration, UserAuthActionTypes } from "../../store/actions/UserAuthTypes";
import { LOCAL_STORAGE_LAST_ACTIVITY } from "../../Constants";
import { LookerContentType } from "../../AppModels";
import { ICustomDrugList, IDrugListSaveResult } from "../CustomAdherenceDrugListManagement/CustomAdherenceDrugListModels";
import {
  IETLRunStatus,
  INotification,
  IReadLogEntry,
  IRealmUser,
} from "../NotificationsManagement/NotificationsModels";

export interface IApiError extends Error {
  status: string;
  errorCode: number;
}

export enum HttpMethod {
  GET = "GET",
  PATCH = "PATCH",
  POST = "POST",
  DELETE = "DELETE",
  PUT = "PUT",
}

export enum APIs {
  PRINT_DESIGNER,
  APS,
  NOTIFICATIONS,
}

export enum LookerApiOperation {
  GET_FOLDER_EDIT_PERMISSIONS = "Get Folder Edit Permissions",
  DELETE_USER = "Delete User",
  GET_CURRENT_USER = "Get Current User",
  GET_SSO_EMBEDDED_URL = "Get SSO Embedded Url",
  SEARCH_FOLDERS = "Search Folders",
  SEARCH_DASHBOARDS = "Search Dashboards",
  GET_FOLDER = "Get Folder",
  MOVE_FOLDER = "Move Folder",
  GET_ALL_FOLDERS = "Get All Folders",
  GET_FOLDER_CHILDREN = "Get Folder Childred",
  SEARCH_FOLDER_CHILDREN = "Search Folder Children",
  GET_FOLDER_PARENT = "Get Folder Parent",
  GET_FOLDER_ANCESTORS = "Get Folder Ancestors",
  GET_FOLDER_LOOKS = "Get Folder Looks",
  GET_FOLDER_DASHBOARDS = "Get Folder Dashboards",
  GET_ALL_LOOKS = "Get All Looks",
  GET_LOOK = "Get Look",
  MOVE_LOOK = "Move Look",
  COPY_LOOK = "Copy Look",
  GET_QUERY_FOR_SLUG = "Get Query For Slug",
  GET_ALL_DASHBOARDS = "Get All Dashboards",
  GET_DASHBOARD = "Get Dashboard",
  MOVE_DASHBOARD = "Move Dashboard",
  COPY_DASHBOARD = "Copy Dashboard",
  GET_DASHBOARD_ELEMENT = "Get Dashboard Element",
  CREATE_FOLDER = "Create Folder",
  CREATE_DASHBOARD = "Create Dashboard",
  GET_CONTENT_THUMBNAIL = "Get Content Thumbnail",
  GET_EMBED_USER = "Get Embed User",
  DELETE_FOLDER = "Delete Folder",
  RENAME_FOLDER = "Rename Folder",
  DELETE_DASHBOARD = "Delete Dashboard",
  DELETE_LOOK = "Delete Look",
  SEARCH_CONTENT_VIEWS = "Search Content Views",
  RECENTLY_RUN_REPORTS = "Recently Run Reports",
  GROUP_USERNAMES = "Group Usernames",
  SEARCH = "Search",
  //Commenting store groups Mar 2023
  /*CREATE_STORE_GROUP = "Create Store Group",
  FIND_STORE_GROUP_BY_ID = "Find Store Group by Id",
  FIND_STORE_GROUPS_BY_FIELDS = "Find Store Groups by Fields",
  DELETE_STORE_GROUP_BY_ID = "Delete Store Group by Id",
  UPDATE_STORE_GROUP = "Update Store Group",*/
  GET_SCHEDULE_ACCESS = "Get Schedule Access",
  GET_SCHEDULED_PLANS = "Get Scheduled Plans",
  GET_RUN_ONCE_SCHEDULED_PLANS = "Get Run Once Scheduled Plans",
  GET_CORPORATE_HIERARCHY = "Get Corporate Hierarchy",
  DELETE_SCHEDULED_PLAN = "Delete Scheduled Plan",
  GET_CUSTOM_GROUPS = "Get Custom Groups",
  GET_CUSTOM_GROUP_DETAILS = "Get Custom Group Details",
  GET_LICENSED_USERS = "Get Licensed Users",
  GET_LICENSE_INFO = "Get License Info",
  GET_GROUP_DETAILS = "Get Group Details",
  ADD_GROUP_TO_GROUP = "Add Group to Group",
  REMOVE_GROUP_FROM_GROUP = "Remove Group from Group",
  ADD_USER_TO_GROUP = "Add User to Group",
  REMOVE_USER_FROM_GROUP = "Remove User from Group",
  CREATE_GROUP = "Create Group",
  DELETE_GROUP = "Delete Group",
  FOLDER_PERMISSION_STATUS = "Folder Permission Status",
  SET_FOLDER_PERMISSION = "Set Folder Permission",
  ADMIN_GET_SCHEDULED_PLANS = "Admin Get Scheduled Plans",
  ADMIN_DELETE_SCHEDULED_PLAN = "Admin Delete Scheduled Plan",
  ADMIN_SET_DOWNLOAD = "Admin Set Download Permission",
  ADMIN_SET_SCHEDULE = "Admin Set Schedule Permission",
  SET_USER_HOLDS_LICENSE = "Set User Holds License",
  SET_USER_HOLDS_STORE_LICENSE = "Set User Holds Store License",
  GET_REPORTS_DOCUMENTATION = "Get Documentation For Reports",
  GET_DATA_MODEL_DOCUMENTATION = "Get Documentation For Data Models",
  GET_EXPLORE_TILES_CONFIG = "Get Configuration Information for Explore Tiles",
  GET_EXPLORE_DESCRIPTIONS = "Get Explore Descriptions from Looker",
  GET_APPLICATION_VERSION = "Get Version Number for Application",
  SET_USER_HOME_PAGE = "Set the Home Page at the User Level",
  SET_REALM_HOME_PAGE = "Set the Home Page at the Realm Level",
  SET_GROUP_HOME_PAGE = "Set the Home Page for a user group",
  GET_HOME_PAGE = "Get Home Page for Current User",
  GET_DEFAULT_HOME_PAGE = "Get Default Home Page for Current User",
  GET_LOOK_TITLE = "Get Title for Look given Look ID",
  GET_DASHBOARD_TITLE = "Get Title for Dashboard given Dashboard ID",
  GET_EXPLORE_TITLE = "Get Title for Explore given Model Name and Explore Name",
  SET_PRINT_DESIGNER_ROLE = "Set the Print Designer Role for a Given User",
  SCHEDULED_PLAN_LAST_RUN_STATUS = "Get the Last Run Status for a Given Scheduled Plan",
  BULK_SCHEDULED_PLAN_LAST_RUN_STATUS = "Get the Last Run Status for a List of Scheduled Plans",
  GET_STANDARD_REPORTS = "Get Standard Reports",
  GET_CUSTOMER_SETTINGS = "Get Customer Settings",
  ADD_FAVORITE = "Add Favorite",
  GET_FAVORITES = "Get Favorites",
  DELETE_FAVORITE = "Delete Favorite",
  GET_ALERTS = "Get Alerts",
  DELETE_ALERT = "Delete Alert",
  ADMIN_GET_ALERTS = "Admin Get Alerts",
  ADMIN_DELETE_ALERT = "Admin Delete Alert",
  PRINTD_ACCESS_LOG = "Add to Print Designer Access Log",
  ADMIN_APS_ALL_CUSTOM_DRUG_LISTS = "Get All Custom Drug Lists in the current Realm",
  ADMIN_APS_CUSTOM_LIST_DETAILS = "Get Details of a Custom Drug List",
  ADMIN_APS_DELETE_CUSTOM_LIST = "Delete a Custom Drug List",
  ADMIN_APS_SAVE_VERIFY_CUSTOM_LIST = "Save and Verify a Custom Drug List",
  GET_LOGIN_CONFIG = "Get Login Configuration for Realm",
  GET_NOTIFICATIONS = "Get Notifications for the Current User",
  GET_ALL_NOTIFICATIONS = "Get All Notifications for all Realms and Users",
  SET_NOTIFICATION_STATUS = "Toggle Notification as Read or Unread",
  SET_NOTIFICATION_STATUS_BULK = "Toggle Notification as Read or Unread for Multiple Notifications",
  CREATE_NOTIFICATION = "Create a new Notification to be Pushed to Users",
  GET_REALMS = "Get Realms from Notifications API",
  GET_USERS_BY_REALMS = "Get Users by Realms from Notifications API",
  DELETE_NOTIFICATION = "Delete a Notification By ID",
  EDIT_NOTIFICATION = "Edit a Notification By ID",
  RUN_SCHEDULE_ONCE_BY_ID = "Run Schedule Once By Id",
  GET_DATA_LAST_REFRESH_DATETIME = "Get Data Last Refresh DateTime",
  GET_LAST_ETL_COMPLETED = "Get Last Completed ETL Job",
}

export type LookerApiRecord = Record<LookerApiOperation, ILookerOperation>;

//const STORE_GROUP_PREFIX = "/stores";
const ADMIN_PREFIX = "/admin";
const SSO_PREFIX = "/looker";
const DOCUMENTATION_PREFIX = "/documentation";
const USER_PREFIX = "/user";
const USAGE_PREFIX = "/usage";
const LOGIN_PREFIX = "/login";

const apiOperations: LookerApiRecord = {
  // GET_FOLDER_EDIT_PERMISSIONS = "Get Folder Edit Permissions",
  "Get Folder Edit Permissions": {
    type: LookerApiOperation.GET_FOLDER_EDIT_PERMISSIONS,
    path: "/folders/editpermissions",
    method: HttpMethod.GET,
  },
  // DELETE_USER = "Delete User",
  "Delete User": {
    type: LookerApiOperation.DELETE_USER,
    path: "/users",
    method: HttpMethod.DELETE,
    pathPrefix: ADMIN_PREFIX,
  },
  "Get Current User": {
    type: LookerApiOperation.GET_CURRENT_USER,
    path: "/user",
    method: HttpMethod.GET,
  },
  "Get SSO Embedded Url": {
    type: LookerApiOperation.GET_SSO_EMBEDDED_URL,
    path: "/sso,S,",
    method: HttpMethod.GET,
    pathPrefix: SSO_PREFIX,
  },
  "Search Folders": {
    type: LookerApiOperation.SEARCH_FOLDERS,
    path: "/folders/search",
    method: HttpMethod.GET,
  },
  "Search Dashboards": {
    type: LookerApiOperation.SEARCH_DASHBOARDS,
    path: "/dashboards/search",
    method: HttpMethod.GET,
  },
  "Get Folder": {
    type: LookerApiOperation.GET_FOLDER,
    path: "/folders/,S,",
    method: HttpMethod.GET,
  },
  "Move Folder": {
    type: LookerApiOperation.MOVE_FOLDER,
    path: "/folders/move/,S,",
    method: HttpMethod.PATCH,
  },
  "Get All Folders": {
    type: LookerApiOperation.GET_ALL_FOLDERS,
    path: "/folders",
    method: HttpMethod.GET,
  },
  "Get Folder Childred": {
    type: LookerApiOperation.GET_FOLDER_CHILDREN,
    path: "/folders/,S,/children",
    method: HttpMethod.GET,
  },
  "Search Folder Children": {
    type: LookerApiOperation.SEARCH_FOLDER_CHILDREN,
    path: "/folders/,S,/children/search",
    method: HttpMethod.GET,
  },
  "Get Folder Parent": {
    type: LookerApiOperation.GET_FOLDER_PARENT,
    path: "/folders/,S,/parent",
    method: HttpMethod.GET,
  },
  "Get Folder Ancestors": {
    type: LookerApiOperation.GET_FOLDER_ANCESTORS,
    path: "/folders/,S,/ancestors",
    method: HttpMethod.GET,
  },
  "Get Folder Looks": {
    type: LookerApiOperation.GET_FOLDER_LOOKS,
    path: "/folders/,S,/looks",
    method: HttpMethod.GET,
  },
  "Get Folder Dashboards": {
    type: LookerApiOperation.GET_FOLDER_DASHBOARDS,
    path: "/folders/,S,/dashboards",
    method: HttpMethod.GET,
  },
  "Get All Looks": {
    type: LookerApiOperation.GET_ALL_LOOKS,
    path: "/looks",
    method: HttpMethod.GET,
  },
  "Get Look": {
    type: LookerApiOperation.GET_LOOK,
    path: "/looks/,S,",
    method: HttpMethod.GET,
  },
  "Move Look": {
    type: LookerApiOperation.MOVE_LOOK,
    path: "/looks/move/,S,",
    method: HttpMethod.PATCH,
  },
  "Copy Look": {
    type: LookerApiOperation.COPY_LOOK,
    path: "/looks/copy/,S,",
    method: HttpMethod.POST,
  },
  "Get Query For Slug": {
    type: LookerApiOperation.GET_QUERY_FOR_SLUG,
    path: "/queries/slug/,S,",
    method: HttpMethod.GET,
  },
  "Get All Dashboards": {
    type: LookerApiOperation.GET_ALL_DASHBOARDS,
    path: "/dashboards",
    method: HttpMethod.GET,
  },
  "Get Dashboard": {
    type: LookerApiOperation.GET_DASHBOARD,
    path: "/dashboards/,S,",
    method: HttpMethod.GET,
  },
  "Move Dashboard": {
    type: LookerApiOperation.MOVE_DASHBOARD,
    path: "/dashboards/move/,S,",
    method: HttpMethod.PATCH,
  },
  "Copy Dashboard": {
    type: LookerApiOperation.COPY_DASHBOARD,
    path: "/dashboards/copy/,S,",
    method: HttpMethod.POST,
  },
  "Get Dashboard Element": {
    type: LookerApiOperation.GET_DASHBOARD_ELEMENT,
    path: "/dashboard_elements/,S,",
    method: HttpMethod.GET,
  },
  "Create Folder": {
    type: LookerApiOperation.CREATE_FOLDER,
    path: "/folders",
    method: HttpMethod.POST,
  },
  "Create Dashboard": {
    type: LookerApiOperation.CREATE_DASHBOARD,
    path: "/dashboards",
    method: HttpMethod.POST,
  },
  "Get Content Thumbnail": {
    type: LookerApiOperation.GET_CONTENT_THUMBNAIL,
    path: "/content_thumbnail/png/,S,",
    method: HttpMethod.GET,
  },
  "Get Embed User": {
    type: LookerApiOperation.GET_EMBED_USER,
    path: "/user",
    method: HttpMethod.GET,
  },
  "Delete Folder": {
    type: LookerApiOperation.DELETE_FOLDER,
    path: "/folders/,S,",
    method: HttpMethod.DELETE,
  },
  "Rename Folder": {
    type: LookerApiOperation.RENAME_FOLDER,
    path: "/folders/rename/,S,",
    method: HttpMethod.PATCH,
  },
  "Delete Dashboard": {
    type: LookerApiOperation.DELETE_DASHBOARD,
    path: "/dashboards/,S,",
    method: HttpMethod.DELETE,
  },
  "Delete Look": {
    type: LookerApiOperation.DELETE_LOOK,
    path: "/looks/,S,",
    method: HttpMethod.DELETE,
  },
  "Search Content Views": {
    type: LookerApiOperation.SEARCH_CONTENT_VIEWS,
    path: "/content_view/search",
    method: HttpMethod.GET,
  },
  "Recently Run Reports": {
    type: LookerApiOperation.RECENTLY_RUN_REPORTS,
    path: "/recent/,S,",
    method: HttpMethod.GET,
  },
  "Group Usernames": {
    type: LookerApiOperation.GROUP_USERNAMES,
    path: "/group/usernames",
    method: HttpMethod.GET,
  },
  Search: {
    type: LookerApiOperation.SEARCH,
    path: "/search",
    method: HttpMethod.GET,
  },
  //Commenting Store groups March 2023
  // CREATE_STORE_GROUP = "Create Store Group",
  /*"Create Store Group": {
    type: LookerApiOperation.CREATE_STORE_GROUP,
    path: "",
    method: HttpMethod.POST,
    pathPrefix: STORE_GROUP_PREFIX,
  },
  // FIND_STORE_GROUP_BY_ID = "Find Store Group by Id",
  "Find Store Group by Id": {
    type: LookerApiOperation.FIND_STORE_GROUP_BY_ID,
    path: "/,N,",
    method: HttpMethod.GET,
    pathPrefix: STORE_GROUP_PREFIX,
  },
  // FIND_STORE_GROUPS_BY_FIELDS = "Find Store Groups by Fields",
  "Find Store Groups by Fields": {
    type: LookerApiOperation.FIND_STORE_GROUPS_BY_FIELDS,
    path: "",
    method: HttpMethod.GET,
    pathPrefix: STORE_GROUP_PREFIX,
  },
  // DELETE_STORE_GROUP_BY_ID = "Delete Store Group by Id",
  "Delete Store Group by Id": {
    type: LookerApiOperation.DELETE_STORE_GROUP_BY_ID,
    path: "/,N,",
    method: HttpMethod.DELETE,
    pathPrefix: STORE_GROUP_PREFIX,
  },
  // UPDATE_STORE_GROUP = "Update Store Group",
  "Update Store Group": {
    type: LookerApiOperation.UPDATE_STORE_GROUP,
    path: "",
    method: HttpMethod.PATCH,
    pathPrefix: STORE_GROUP_PREFIX,
  },*/
  //   GET_SCHEDULE_ACCESS = "Get Schedule Access",
  "Get Schedule Access": {
    type: LookerApiOperation.GET_SCHEDULE_ACCESS,
    path: "/schedule_access",
    method: HttpMethod.GET,
  },
  //   GET_SCHEDULED_PLANS = "Get Scheduled Plans",
  "Get Scheduled Plans": {
    type: LookerApiOperation.GET_SCHEDULED_PLANS,
    path: "/scheduled_plans",
    method: HttpMethod.GET,
  },
  //   GET_RUN_ONCE_SCHEDULED_PLANS = "Get Run Once Scheduled Plans",
  "Get Run Once Scheduled Plans": {
    type: LookerApiOperation.GET_RUN_ONCE_SCHEDULED_PLANS,
    path: "/run_once_scheduled_plans",
    method: HttpMethod.GET,
  },
  //   GET_CORPORATE_HEIRARCHY = "Get Corporate Hierarchy",
  "Get Corporate Hierarchy": {
    type: LookerApiOperation.GET_CORPORATE_HIERARCHY,
    path: "/corporate_hierarchy",
    method: HttpMethod.GET,
  },
  // DELETE_SCHEDULED_PLAN = "Delete Scheduled Plan",
  "Delete Scheduled Plan": {
    type: LookerApiOperation.DELETE_SCHEDULED_PLAN,
    path: "/scheduled_plans/,S,",
    method: HttpMethod.DELETE,
  },
  // RUN_SCHEDULE_ONCE_BY_ID = "Run Schedule Once By Id",
  "Run Schedule Once By Id": {
    type: LookerApiOperation.RUN_SCHEDULE_ONCE_BY_ID,
    path: "/scheduled_plans/,S,/run_once",
    method: HttpMethod.POST,
  },
  // GET_CUSTOM_GROUPS = "Get Custom Groups",
  "Get Custom Groups": {
    type: LookerApiOperation.GET_CUSTOM_GROUPS,
    path: "/groups/custom",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  //   GET_CUSTOM_GROUP_DETAILS = "Get Custom Group Detailss",
  "Get Custom Group Details": {
    type: LookerApiOperation.GET_CUSTOM_GROUP_DETAILS,
    path: "/groups/details",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  // GET_LICENSED_USERS = "Get Licensed Users",
  "Get Licensed Users": {
    type: LookerApiOperation.GET_LICENSED_USERS,
    path: "/users/licensed",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  //   GET_LICENSE_INFO = "Get License Info",
  "Get License Info": {
    type: LookerApiOperation.GET_LICENSE_INFO,
    path: "/users/licenseinfo",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  // GET_GROUP_DETAILS = "Get Group Details",
  "Get Group Details": {
    type: LookerApiOperation.GET_GROUP_DETAILS,
    path: "/group/details/,S,",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  // ADD_GROUP_TO_GROUP = "Add Group to Group",
  "Add Group to Group": {
    type: LookerApiOperation.ADD_GROUP_TO_GROUP,
    path: "/group/add/,S,",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // REMOVE_GROUP_FROM_GROUP = "Remove Group from Group",
  "Remove Group from Group": {
    type: LookerApiOperation.REMOVE_GROUP_FROM_GROUP,
    path: "/group/remove/,S,",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // ADD_USER_TO_GROUP = "Add User to Group",
  "Add User to Group": {
    type: LookerApiOperation.ADD_USER_TO_GROUP,
    path: "/group/adduser/,S,",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // REMOVE_USER_FROM_GROUP = "Remove User from Group",
  "Remove User from Group": {
    type: LookerApiOperation.REMOVE_USER_FROM_GROUP,
    path: "/group/removeuser/,S,",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // CREATE_GROUP = "Create Group",
  "Create Group": {
    type: LookerApiOperation.CREATE_GROUP,
    path: "/group/,S,",
    method: HttpMethod.POST,
    pathPrefix: ADMIN_PREFIX,
  },
  // DELETE_GROUP = "Delete Group",
  "Delete Group": {
    type: LookerApiOperation.DELETE_GROUP,
    path: "/group/,S,",
    method: HttpMethod.DELETE,
    pathPrefix: ADMIN_PREFIX,
  },
  // FOLDER_PERMISSION_STATUS = "Folder Permission Status",
  "Folder Permission Status": {
    type: LookerApiOperation.FOLDER_PERMISSION_STATUS,
    path: "/permissions/,S,",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  // SET_FOLDER_PERMISSION = "Set Folder Permission",
  "Set Folder Permission": {
    type: LookerApiOperation.SET_FOLDER_PERMISSION,
    path: "/permissions/,S,",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // ADMIN_GET_SCHEDULED_PLANS = "Admin Get Scheduled Plans",
  "Admin Get Scheduled Plans": {
    type: LookerApiOperation.ADMIN_GET_SCHEDULED_PLANS,
    path: "/scheduled_plans",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  // ADMIN_DELETE_SCHEDULED_PLAN = "Admin Delete Scheduled Plan",
  "Admin Delete Scheduled Plan": {
    type: LookerApiOperation.ADMIN_DELETE_SCHEDULED_PLAN,
    path: "/scheduled_plans/,S,",
    method: HttpMethod.DELETE,
    pathPrefix: ADMIN_PREFIX,
  },
  // ADMIN_SET_DOWNLOAD = "Admin Set Download Permission",
  "Admin Set Download Permission": {
    type: LookerApiOperation.ADMIN_SET_DOWNLOAD,
    path: "/users/download",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // ADMIN_SET_SCHEDULE = "Admin Set Schedule Permission",
  "Admin Set Schedule Permission": {
    type: LookerApiOperation.ADMIN_SET_SCHEDULE,
    path: "/users/schedule",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // SET_USER_HOLDS_LICENSE = "Set User Holds License",
  "Set User Holds License": {
    type: LookerApiOperation.SET_USER_HOLDS_LICENSE,
    path: "/users/holdslicense",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  // SET_USER_HOLDS_STORE_LICENSE = "Set User Holds Store License",
  "Set User Holds Store License": {
    type: LookerApiOperation.SET_USER_HOLDS_STORE_LICENSE,
    path: "/users/holdslicense",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  "Get Documentation For Reports": {
    type: LookerApiOperation.GET_REPORTS_DOCUMENTATION,
    path: "/get_reports_documentation",
    method: HttpMethod.GET,
    pathPrefix: DOCUMENTATION_PREFIX,
  },
  "Get Documentation For Data Models": {
    type: LookerApiOperation.GET_DATA_MODEL_DOCUMENTATION,
    path: "/get_data_model_documentation",
    method: HttpMethod.GET,
    pathPrefix: DOCUMENTATION_PREFIX,
  },
  "Get Configuration Information for Explore Tiles": {
    type: LookerApiOperation.GET_EXPLORE_TILES_CONFIG,
    path: "/get_explore_tiles",
    method: HttpMethod.GET,
    pathPrefix: DOCUMENTATION_PREFIX,
  },
  "Get Explore Descriptions from Looker": {
    type: LookerApiOperation.GET_EXPLORE_DESCRIPTIONS,
    path: "/explore_descriptions",
    method: HttpMethod.GET,
    pathPrefix: DOCUMENTATION_PREFIX,
  },
  "Get Version Number for Application": {
    type: LookerApiOperation.GET_APPLICATION_VERSION,
    path: "/version",
    method: HttpMethod.GET,
    pathPrefix: DOCUMENTATION_PREFIX,
  },
  "Set the Home Page at the User Level": {
    type: LookerApiOperation.SET_USER_HOME_PAGE,
    path: "/users/settings/home_page",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  "Set the Home Page at the Realm Level": {
    type: LookerApiOperation.SET_REALM_HOME_PAGE,
    path: "/realm/settings/home_page",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  "Set the Home Page for a user group": {
    type: LookerApiOperation.SET_GROUP_HOME_PAGE,
    path: "/group/settings/home_page",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  "Get Home Page for Current User": {
    type: LookerApiOperation.GET_HOME_PAGE,
    path: "/home_page",
    method: HttpMethod.GET,
    pathPrefix: USER_PREFIX,
  },
  "Get Default Home Page for Current User": {
    type: LookerApiOperation.GET_DEFAULT_HOME_PAGE,
    path: "/home_page_default",
    method: HttpMethod.GET,
    pathPrefix: USER_PREFIX,
  },
  "Get Title for Look given Look ID": {
    type: LookerApiOperation.GET_LOOK_TITLE,
    path: "/content/looks/title",
    method: HttpMethod.GET,
    pathPrefix: SSO_PREFIX,
  },
  "Get Title for Dashboard given Dashboard ID": {
    type: LookerApiOperation.GET_DASHBOARD_TITLE,
    path: "/content/dashboards/title",
    method: HttpMethod.GET,
    pathPrefix: SSO_PREFIX,
  },
  "Get Title for Explore given Model Name and Explore Name": {
    type: LookerApiOperation.GET_EXPLORE_TITLE,
    path: "/content/explores/title",
    method: HttpMethod.GET,
    pathPrefix: SSO_PREFIX,
  },
  "Set the Print Designer Role for a Given User": {
    type: LookerApiOperation.SET_PRINT_DESIGNER_ROLE,
    path: "/users/print_designer_role",
    method: HttpMethod.PATCH,
    pathPrefix: ADMIN_PREFIX,
  },
  "Get the Last Run Status for a Given Scheduled Plan": {
    type: LookerApiOperation.SCHEDULED_PLAN_LAST_RUN_STATUS,
    path: "/scheduled_plans/last_run_status/,S,",
    method: HttpMethod.GET,
  },
  "Get the Last Run Status for a List of Scheduled Plans": {
    type: LookerApiOperation.BULK_SCHEDULED_PLAN_LAST_RUN_STATUS,
    path: "/scheduled_plans/bulk_last_run_status",
    method: HttpMethod.POST,
  },
  "Get Standard Reports": {
    type: LookerApiOperation.GET_STANDARD_REPORTS,
    path: "/standard_reports",
    method: HttpMethod.GET,
    pathPrefix: USER_PREFIX,
  },
  "Get Customer Settings": {
    type: LookerApiOperation.GET_CUSTOMER_SETTINGS,
    path: "/customer_settings",
    method: HttpMethod.GET,
    pathPrefix: USER_PREFIX,
  },
  "Add Favorite": {
    type: LookerApiOperation.ADD_FAVORITE,
    path: "/favorites",
    method: HttpMethod.POST,
  },
  "Get Favorites": {
    type: LookerApiOperation.GET_FAVORITES,
    path: "/favorites",
    method: HttpMethod.GET,
  },
  "Delete Favorite": {
    type: LookerApiOperation.DELETE_FAVORITE,
    path: "/favorites/,S,",
    method: HttpMethod.DELETE,
  },
  //   GET_ALERTS = "Get Alerts",
  "Get Alerts": {
    type: LookerApiOperation.GET_ALERTS,
    path: "/alerts",
    method: HttpMethod.GET,
  },
  // DELETE_ALERT = "Delete Alert",
  "Delete Alert": {
    type: LookerApiOperation.DELETE_ALERT,
    path: "/alerts/,S,",
    method: HttpMethod.DELETE,
  },
  // ADMIN_GET_ALERTS = "Admin Get Alerts",
  "Admin Get Alerts": {
    type: LookerApiOperation.ADMIN_GET_ALERTS,
    path: "/alerts",
    method: HttpMethod.GET,
    pathPrefix: ADMIN_PREFIX,
  },
  // ADMIN_DELETE_ALERT = "Admin Delete Alert",
  "Admin Delete Alert": {
    type: LookerApiOperation.ADMIN_DELETE_ALERT,
    path: "/alerts/,S,",
    method: HttpMethod.DELETE,
    pathPrefix: ADMIN_PREFIX,
  },
  // PRINTD_ACCESS_LOG = "Add to Print Designer Access Log"
  "Add to Print Designer Access Log": {
    type: LookerApiOperation.PRINTD_ACCESS_LOG,
    path: "/access_log",
    method: HttpMethod.POST,
    pathPrefix: USAGE_PREFIX,
    api: APIs.PRINT_DESIGNER,
  },
  "Get All Custom Drug Lists in the current Realm": {
    type: LookerApiOperation.ADMIN_APS_ALL_CUSTOM_DRUG_LISTS,
    path: "/admin/custom_drug_lists",
    method: HttpMethod.GET,
    pathPrefix: "",
    api: APIs.APS,
  },
  "Get Details of a Custom Drug List": {
    type: LookerApiOperation.ADMIN_APS_CUSTOM_LIST_DETAILS,
    path: "/admin/custom_drug_lists/,S,",
    method: HttpMethod.GET,
    pathPrefix: "",
    api: APIs.APS,
  },
  "Delete a Custom Drug List": {
    type: LookerApiOperation.ADMIN_APS_DELETE_CUSTOM_LIST,
    path: "/admin/custom_drug_lists/,S,",
    method: HttpMethod.DELETE,
    pathPrefix: "",
    api: APIs.APS,
  },
  "Save and Verify a Custom Drug List": {
    type: LookerApiOperation.ADMIN_APS_SAVE_VERIFY_CUSTOM_LIST,
    path: "/admin/custom_drug_lists/save",
    method: HttpMethod.POST,
    pathPrefix: "",
    api: APIs.APS,
  },
  "Get Login Configuration for Realm": {
    type: LookerApiOperation.GET_LOGIN_CONFIG,
    path: "/realm_configuration",
    method: HttpMethod.POST,
    pathPrefix: LOGIN_PREFIX,
  },
  "Get Notifications for the Current User": {
    type: LookerApiOperation.GET_NOTIFICATIONS,
    path: "/all",
    method: HttpMethod.GET,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Get All Notifications for all Realms and Users": {
    type: LookerApiOperation.GET_ALL_NOTIFICATIONS,
    path: "/admin/all",
    method: HttpMethod.GET,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Toggle Notification as Read or Unread": {
    type: LookerApiOperation.SET_NOTIFICATION_STATUS,
    path: "/,S,/status",
    method: HttpMethod.PUT,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Toggle Notification as Read or Unread for Multiple Notifications": {
    type: LookerApiOperation.SET_NOTIFICATION_STATUS_BULK,
    path: "/bulk/status",
    method: HttpMethod.PUT,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Create a new Notification to be Pushed to Users": {
    type: LookerApiOperation.CREATE_NOTIFICATION,
    path: "/create",
    method: HttpMethod.POST,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Get Realms from Notifications API": {
    type: LookerApiOperation.GET_REALMS,
    path: "/realms",
    method: HttpMethod.GET,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Get Users by Realms from Notifications API": {
    type: LookerApiOperation.GET_USERS_BY_REALMS,
    path: "/users",
    method: HttpMethod.POST,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Delete a Notification By ID": {
    type: LookerApiOperation.DELETE_NOTIFICATION,
    path: "/admin/delete",
    method: HttpMethod.DELETE,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Edit a Notification By ID": {
    type: LookerApiOperation.EDIT_NOTIFICATION,
    path: "/admin/edit",
    method: HttpMethod.PATCH,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Get Data Last Refresh DateTime": {
    type: LookerApiOperation.GET_DATA_LAST_REFRESH_DATETIME,
    path: "/metrics/data/qlik_refreshed_date",
    method: HttpMethod.GET,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
  "Get Last Completed ETL Job": {
    type: LookerApiOperation.GET_LAST_ETL_COMPLETED,
    path: "/metrics/data/replication_completed_date",
    method: HttpMethod.GET,
    pathPrefix: "",
    api: APIs.NOTIFICATIONS,
  },
};

/*export interface IStoreGroup {
  readonly id: number;
  readonly realm: string;
  readonly username: string;
  readonly groupName: string;
  description: string;
  stores: string; // comma seprated list of store facility Ids
  readonly version: number; // used for concurrent update control
}*/

export interface ILookerOperation {
  type: LookerApiOperation;
  path: string;
  method: HttpMethod;
  pathIdString?: string;
  pathIdNumber?: number;
  pathPrefix?: string;
  api?: APIs;
  headers?: {};
}

export interface ILookerApiBody {}

const formatPath = (
  operation: ILookerOperation,
  queryParams: object,
  serverHost: string
): string => {
  let contentPrefix = "/looker/content";
  if (operation.pathPrefix || operation.pathPrefix === "") {
    contentPrefix = operation.pathPrefix;
  }
  let path = serverHost + contentPrefix + operation.path;
  if (operation.pathIdNumber) {
    path = path.replace(",N,", "" + operation.pathIdNumber);
  }
  if (operation.pathIdString) {
    path = path.replace(",S,", operation.pathIdString);
  }
  if (queryParams) {
    path = path + "?";
    let first = true;
    for (const [key, value] of Object.entries(queryParams)) {
      if (first) {
        path = path + key + "=" + value;
        first = false;
      } else {
        path = path + "&" + key + "=" + value;
      }
    }
  }
  return path;
};

export const runApiOp = async (
  operation: ILookerOperation,
  queryParams: object,
  body: ILookerApiBody,
  setOpResult: (result: any) => void,
  setError: (error: IApiError) => void
) => {
  let serverHost = "";
  switch (operation.api) {
    case APIs.PRINT_DESIGNER: {
      serverHost = process.env.REACT_APP_PRINT_DESIGNER_API_BASE_URL;
      break;
    }
    case APIs.APS: {
      serverHost = process.env.REACT_APP_APS_API_BASE_URL;
      break;
    }
    case APIs.NOTIFICATIONS: {
      serverHost = process.env.REACT_APP_NOTIFICATIONS_API_BASE_URL;
      break;
    }
    default: {
      serverHost = process.env.REACT_APP_DATA_INSIGHTS_API_BASE_URL;
      break;
    }
  }
  const path = formatPath(operation, queryParams, serverHost);
  console.log("runApiOp: operation = ", operation);
  console.log("runApiOp: " + operation.method + " path = " + path);
  if (body) {
    console.log("runApiOp: body = ", body);
  }
  const headers = operation.headers ? operation.headers : {};

  if (operation.type !== LookerApiOperation.GET_NOTIFICATIONS) {
    localStorage.setItem(LOCAL_STORAGE_LAST_ACTIVITY, Date.now().toString());
  }
  const responseCallback = (response: any): void => {
    setOpResult(response.data);
  };

  const errorCallback = (ex: any): void => {
    let errorMessage = ex?.response?.data;
    if (ex && ex.response && ex.response.status === 401) {
      errorMessage = "Invalid Session";
      console.log("Unauthorized Response from API, redirect to login");
      store.dispatch({
        type: UserAuthActionTypes.LOGOUT,
      });
      return;
    }
    if (!errorMessage) {
      if (ex.message) {
        errorMessage = ex.response;
      } else {
        errorMessage = "Unknown error.";
      }
    }
    console.log(
      `ERROR in ${operation.method} ${operation.type} ${
        errorMessage ? errorMessage.data : undefined
      }`
    );
    if (setError !== undefined) {
      setError({
        name: "API Error",
        message: errorMessage?.messages,
        status: errorMessage?.status,
        errorCode: errorMessage?.errorCode,
      });
    }
  };

  switch (operation.method) {
    case HttpMethod.GET:
      await axios
        .get(path, { headers: headers, withCredentials: true })
        .then(responseCallback)
        .catch(errorCallback);
      break;

    case HttpMethod.DELETE:
      await axios
        .delete(path, { headers: headers, withCredentials: true })
        .then(responseCallback)
        .catch(errorCallback);
      break;

    case HttpMethod.PATCH:
      await axios
        .patch(path, body, { headers: headers, withCredentials: true })
        .then(responseCallback)
        .catch(errorCallback);
      break;

    case HttpMethod.POST:
      await axios
        .post(path, body, { headers: headers, withCredentials: true })
        .then(responseCallback)
        .catch(errorCallback);
      break;

    case HttpMethod.PUT:
      await axios
        .put(path, body, { headers: headers, withCredentials: true })
        .then(responseCallback)
        .catch(errorCallback);
      break;
  }
};

//March 2023 - I don't think these are in use, commenting
/*const createStoreGroup = async (
  groupName: string, // required
  description: string, // may be null
  isShared: string, // 'Y', or 'N', or null (defaults to not shared)
  stores: string, // Comma seperated list of store Ids. may be null (defaults to empty list)
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.CREATE_STORE_GROUP];
  let queryParams: object = { groupName: groupName };
  if (description) {
    queryParams = { ...queryParams, description: description };
  }
  if (isShared) {
    queryParams = { ...queryParams, isShared: isShared };
  }
  if (stores) {
    queryParams = { ...queryParams, stores: stores };
  }
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const findStoreGroupsByFields = async (
  groupName: string, // optional, all storeGroups for category if null
  isShared: string, // 'Y', or 'N', or null (defaults to not shared)
  setResult: (result: IStoreGroup[]) => void,
  setError: (error: Error) => void
) => {
  const operation =
    apiOperations[LookerApiOperation.FIND_STORE_GROUPS_BY_FIELDS];
  let queryParams: object = {};
  if (isShared) {
    queryParams = { ...queryParams, isShared: isShared };
  }
  if (groupName) {
    queryParams = { ...queryParams, groupName: groupName };
  }
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const findStoreGroupById = async (
  storeGroupId: number,
  setResult: (result: IStoreGroup) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.FIND_STORE_GROUP_BY_ID];
  operation = { ...operation, pathIdNumber: storeGroupId };
  const queryParams: object = null;

  await runApiOp(operation, queryParams, null, setResult, setError);
};

const deleteStoreGroupById = async (
  storeGroupId: number,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_STORE_GROUP_BY_ID];
  operation = { ...operation, pathIdNumber: storeGroupId };
  const queryParams: object = null;

  await runApiOp(operation, queryParams, null, setResult, setError);
};

const updateStoreGroup = async (
  storeGroup: IStoreGroup, // This should be a storeGroup object retrieved through the API
  // The only fields that should be modified are the array of stores and/or the description
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.UPDATE_STORE_GROUP];
  const queryParams: object = null;

  await runApiOp(operation, queryParams, storeGroup, setResult, setError);
};*/

const getCurrentUser = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_CURRENT_USER];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getSsoEmbeddedUrl = async (
  path: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_SSO_EMBEDDED_URL];
  operation = {
    ...operation,
    pathIdString: path,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getGroupUsernames = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GROUP_USERNAMES];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const searchFoldersByName = async (
  folderName: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.SEARCH_FOLDERS];
  let queryParams: object = { name: folderName };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const searchDashboardsByName = async (
  title: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.SEARCH_DASHBOARDS];
  let queryParams: object = { title: title };
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const searchFolders = async (
  queryParams: object,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.SEARCH_FOLDERS];
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getFolder = async (
  folderId: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FOLDER];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  const queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const moveFolder = async (
  folderId: string,
  queryParams: object,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.MOVE_FOLDER];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  // For the moveFolder operation, the queryParams will include from=fromFolderId & to=toFolderId
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const renameFolder = async (
  folderId: string,
  newName: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.RENAME_FOLDER];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  const queryParams: object = {
    name: newName,
  };
  // For the moveFolder operation, the queryParams will include from=fromFolderId & to=toFolderId
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getAllFolders = async (
  queryParams: object,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_ALL_FOLDERS];
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getFolderChildren = async (
  folderId: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FOLDER_CHILDREN];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  const queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const searchFolderChildren = async (
  folderId: string,
  queryParams: object,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SEARCH_FOLDER_CHILDREN];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getFolderParent = async (
  folderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FOLDER_PARENT];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getFolderAncestors = async (
  folderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FOLDER_ANCESTORS];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getFolderLooks = async (
  folderId: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FOLDER_LOOKS];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  const queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getFolderDashboards = async (
  folderId: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FOLDER_DASHBOARDS];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  const queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getAllLooks = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_ALL_LOOKS];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getLook = async (
  lookId: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_LOOK];
  operation = {
    ...operation,
    pathIdString: lookId,
  };
  const queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getQueryForSlug = async (
  slug: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_QUERY_FOR_SLUG];
  operation = {
    ...operation,
    pathIdString: slug,
  };
  const queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const searchContentViews = async (
  userId: string,
  setResult: (result: object[]) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.SEARCH_CONTENT_VIEWS];
  const queryParams: object = {
    user_id: userId,
    fields:
      "id,look_id,dashboard_id,content_metadata_id,user_id,last_viewed_at",
    sorts: "last_viewed_at",
    // filter_or: true,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const recentlyRunReports = async (
  userId: string,
  setResult: (result: object[]) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.RECENTLY_RUN_REPORTS];
  operation = {
    ...operation,
    pathIdString: userId,
  };
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const moveDashboard = async (
  dashboardId: string,
  toFolderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.MOVE_DASHBOARD];
  operation = {
    ...operation,
    pathIdString: dashboardId,
  };
  let queryParams: object = {
    to: toFolderId,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const copyDashboard = async (
  dashboardId: string,
  toFolderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.COPY_DASHBOARD];
  operation = {
    ...operation,
    pathIdString: dashboardId,
  };
  let queryParams: object = {
    to: toFolderId,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const moveLook = async (
  lookId: string,
  toFolderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const queryParams: object = {
    to: toFolderId,
  };
  let operation = apiOperations[LookerApiOperation.MOVE_LOOK];
  operation = {
    ...operation,
    pathIdString: lookId,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const copyLook = async (
  lookId: string,
  toFolderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const queryParams: object = {
    to: toFolderId,
  };
  let operation = apiOperations[LookerApiOperation.COPY_LOOK];
  operation = {
    ...operation,
    pathIdString: lookId,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const getAllDashboards = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_ALL_DASHBOARDS];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getDashboard = async (
  dashboardId: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_DASHBOARD];
  operation = {
    ...operation,
    pathIdString: dashboardId,
  };
  let queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getDashboardElement = async (
  dashboardElementId: string,
  fields: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_DASHBOARD_ELEMENT];
  operation = {
    ...operation,
    pathIdString: dashboardElementId,
  };
  let queryParams: object = fields ? { fields: fields } : null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const createFolder = async (
  body: object,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.CREATE_FOLDER];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, body, setResult, setError);
};
const createDashboard = async (
  title: string,
  folderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.CREATE_DASHBOARD];
  let queryParams: object = {
    title: title,
    folderId: folderId,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getContentThumbnail = async (
  resourceId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_CONTENT_THUMBNAIL];
  operation = {
    ...operation,
    pathIdString: resourceId,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const getEmbedUser = async (
  queryParams: object,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_EMBED_USER];

  await runApiOp(operation, queryParams, null, setResult, setError);
};
const deleteFolder = async (
  folderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_FOLDER];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const deleteLook = async (
  lookId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_LOOK];
  operation = {
    ...operation,
    pathIdString: lookId,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const deleteDashboard = async (
  dashboardId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_DASHBOARD];
  operation = {
    ...operation,
    pathIdString: dashboardId,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
const search = async (
  title: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.SEARCH];
  let queryParams: object = {
    title: title,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const getScheduleAccess = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_SCHEDULE_ACCESS];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};

const getScheduledPlans = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_SCHEDULED_PLANS];
  const queryParams: object = null;

  await runApiOp(operation, queryParams, null, setResult, setError);
};

const getRunOnceScheduledPlans = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.GET_RUN_ONCE_SCHEDULED_PLANS];
  const queryParams: object = null;

  await runApiOp(operation, queryParams, null, setResult, setError);
};

const getCorporateHierarchy = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_CORPORATE_HIERARCHY];
  const queryParams: object = null;

  await runApiOp(operation, queryParams, null, setResult, setError);
};

const deleteScheduledPlan = async (
  scheduleId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_SCHEDULED_PLAN];
  operation = {
    ...operation,
    pathIdString: scheduleId,
  };
  const queryParams: object = null;

  await runApiOp(operation, queryParams, null, setResult, setError);
};
// RUN_SCHEDULE_ONCE_BY_ID = "Run Schedule Once By Id"
const runScheduleOnceById = async (
  scheduleId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void,
  userId?: string
) => {
  let operation = apiOperations[LookerApiOperation.RUN_SCHEDULE_ONCE_BY_ID];
  operation = {
    ...operation,
    pathIdString: scheduleId,
  };
  let queryParams: object = null;
  if (userId) {
    queryParams = {
      userId: userId,
    };
  }
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_CUSTOM_GROUPS = "Get Custom Groups",
const getCustomGroups = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_CUSTOM_GROUPS];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_CUSTOM_GROUPS = "Get Custom Groups",
const getCustomGroupDetails = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_CUSTOM_GROUP_DETAILS];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_LICENSED_USERS = "Get Licensed Users",
const getLicensedUsers = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_LICENSED_USERS];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_LICENSE_INFO = "Get License Info",
const getLicenseInfo = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  const operation = apiOperations[LookerApiOperation.GET_LICENSE_INFO];
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_GROUP_DETAILS = "Get Group Details",
const getGroupDetails = async (
  groupname: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_GROUP_DETAILS];
  operation = {
    ...operation,
    pathIdString: groupname,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADD_GROUP_TO_GROUP = "Add Group to Group",
const addGroupToGroup = async (
  groupname: string,
  addGroup: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADD_GROUP_TO_GROUP];
  operation = {
    ...operation,
    pathIdString: groupname,
  };
  let queryParams: object = { child: addGroup };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// REMOVE_GROUP_FROM_GROUP = "Remove Group from Group",
const removeGroupFromGroup = async (
  groupname: string,
  removeGroup: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.REMOVE_GROUP_FROM_GROUP];
  operation = {
    ...operation,
    pathIdString: groupname,
  };
  let queryParams: object = { child: removeGroup };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADD_USER_TO_GROUP = "Add User to Group",
const addUserToGroup = async (
  groupname: string,
  addUser: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADD_USER_TO_GROUP];
  operation = {
    ...operation,
    pathIdString: groupname,
  };
  let queryParams: object = { user: encodeURIComponent(addUser) };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// REMOVE_USER_FROM_GROUP = "Remove User from Group",
const removeUserFromGroup = async (
  groupname: string,
  removeUser: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.REMOVE_USER_FROM_GROUP];
  operation = {
    ...operation,
    pathIdString: groupname,
  };
  let queryParams: object = { user: encodeURIComponent(removeUser) };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// CREATE_GROUP = "Create Group",
const createGroup = async (
  groupname: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.CREATE_GROUP];
  operation = {
    ...operation,
    pathIdString: groupname,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// DELETE_GROUP = "Delete Group",
const deleteGroup = async (
  groupname: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_GROUP];
  operation = {
    ...operation,
    pathIdString: groupname,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// FOLDER_PERMISSION_STATUS = "Folder Permission Status",
const getFolderPermissionStatus = async (
  folderId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.FOLDER_PERMISSION_STATUS];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  let queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// SET_FOLDER_PERMISSION = "Set Folder Permission",
const setFolderPermission = async (
  folderId: string,
  groupname: string,
  access: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SET_FOLDER_PERMISSION];
  operation = {
    ...operation,
    pathIdString: folderId,
  };
  let queryParams: object = { group: groupname, access: access };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADMIN_GET_SCHEDULED_PLANS = "Admin Get Scheduled Plans",
const getScheduledPlansAdmin = async (
  username: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADMIN_GET_SCHEDULED_PLANS];
  const queryParams: object = { username: encodeURIComponent(username) };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADMIN_DELETE_SCHEDULED_PLAN = "Admin Delete Scheduled Plan",
const deleteScheduledPlanAdmin = async (
  scheduleId: string,
  username: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADMIN_DELETE_SCHEDULED_PLAN];
  operation = {
    ...operation,
    pathIdString: scheduleId,
  };
  const queryParams: object = { username: encodeURIComponent(username) };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADMIN_SET_DOWNLOAD = "Admin Set Download Permission",
const setDownloadPermission = async (
  username: string,
  downloadAllowed: boolean,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADMIN_SET_DOWNLOAD];
  const queryParams: object = {
    username: encodeURIComponent(username),
    downloadAllowed: downloadAllowed,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADMIN_SET_SCHEDULE = "Admin Set Schedule Permission",
const setSchedulePermission = async (
  username: string,
  scheduleAllowed: boolean,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADMIN_SET_SCHEDULE];
  const queryParams: object = {
    username: encodeURIComponent(username),
    scheduleAllowed: scheduleAllowed,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// SET_USER_HOLDS_LICENSE = "Set User Holds License",
const setUserHoldsLicense = async (
  username: string,
  holdsLicense: boolean,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SET_USER_HOLDS_LICENSE];
  const queryParams: object = {
    username: encodeURIComponent(username),
    holdsLicense: holdsLicense,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// DELETE_USER = "Delete User",
const deleteUser = async (
  username: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_USER];
  const queryParams: object = { username: encodeURIComponent(username) };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_FOLDER_EDIT_PERMISSIONS = "Get Folder Edit Permissions",
const getFolderEditPermissions = async (
  folderId: string,
  parentId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FOLDER_EDIT_PERMISSIONS];
  const queryParams: object = { folderId: folderId, parentId: parentId };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_REPORTS_DOCUMENTATION = "Get Documentation For Reports"
const getReportsDocumentation = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_REPORTS_DOCUMENTATION];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_DATA_MODEL_DOCUMENTATION = "Get Documentation For Data Models"
const getDataModelDocumentation = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.GET_DATA_MODEL_DOCUMENTATION];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_EXPLORE_TILES_CONFIG = "Get Configuration Information for Explore Tiles"
const getExploreTilesConfig = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_EXPLORE_TILES_CONFIG];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_EXPLORE_DESCRIPTIONS = 'Get Explore Descriptions from Looker'
const getExploreDescriptions = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_EXPLORE_DESCRIPTIONS];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_APPLICATION_VERSION = 'Get Version Number for Application'
const getApplicationVersion = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_APPLICATION_VERSION];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
//SET_USER_HOME_PAGE = "Set the Home Page at the User Level"
const setUserHomePage = async (
  type: string,
  lookerId: string,
  embedUrl: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SET_USER_HOME_PAGE];
  const queryParams: object = {
    type: type,
    lookerId: lookerId,
    embedUrl: embedUrl,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
//SET_REALM_HOME_PAGE = "Set the Home Page at the Realm Level"
const setRealmHomePage = async (
  type: string,
  lookerId: string,
  embedUrl: string,
  customerRealm: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SET_REALM_HOME_PAGE];
  const queryParams: object = {
    type: type,
    lookerId: lookerId,
    embedUrl: embedUrl,
    customerRealm: customerRealm,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// SET_GROUP_HOME_PAGE = "Set the Home Page for a user group"
const setGroupHomePage = async (
  type: string,
  lookerId: string,
  embedUrl: string,
  realmGroupname: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SET_GROUP_HOME_PAGE];
  const queryParams: object = {
    type: type,
    lookerId: lookerId,
    embedUrl: embedUrl,
    realmGroupname: realmGroupname,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_HOME_PAGE = "Get Home Page for Current User
const getHomePage = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_HOME_PAGE];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_DEFAULT_HOME_PAGE = "Get Default Home Page for Current User
const getDefaultHomePage = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_DEFAULT_HOME_PAGE];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_LOOK_TITLE = "Get Title for Look given Look ID"
const getLookTitle = async (
  lookId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_LOOK_TITLE];
  const queryParams: object = { lookId: lookId };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_DASHBOARD_TITLE = "Get Title for Dashboard given Dashboard ID"
const getDashboardTitle = async (
  dashboardId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_DASHBOARD_TITLE];
  const queryParams: object = { dashboardId: dashboardId };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_EXPLORE_TITLE = "Get Title for Explore given Model Name and Explore Name"
const getExploreTitle = async (
  modelName: string,
  exploreName: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_EXPLORE_TITLE];
  const queryParams: object = {
    modelName: modelName,
    exploreName: exploreName,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// SET_PRINT_DESIGNER_ROLE = "Set the Print Designer Role for a Given User"
const setPrintDesignerRole = async (
  username: string,
  printDesignerRole: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SET_PRINT_DESIGNER_ROLE];
  const queryParams: object = {
    username: encodeURIComponent(username),
    printDesignerRole: printDesignerRole,
  };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// SCHEDULED_PLAN_LAST_RUN_STATUS = "Get the Last Run Status for a Given Scheduled Plan"
const getScheduleLastRunStatus = async (
  scheduledPlanId: string,
  setResult: (result: object[]) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.SCHEDULED_PLAN_LAST_RUN_STATUS];
  operation = {
    ...operation,
    pathIdString: scheduledPlanId,
  };
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// BULK_SCHEDULED_PLAN_LAST_RUN_STATUS = "Get the Last Run Status for a List of Scheduled Plans",
const getBulkScheduleLastRunStatus = async (
  scheduledPlanIds: string[],
  setResult: (result: object[]) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.BULK_SCHEDULED_PLAN_LAST_RUN_STATUS];
  const queryParams: object = null;
  const body = {
    scheduled_ids: scheduledPlanIds,
  };
  await runApiOp(operation, queryParams, body, setResult, setError);
};
// GET_STANDARD_REPORTS = "Get Standard Reports"
const getStandardReports = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_STANDARD_REPORTS];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_CUSTOMER_SETTINGS = "Get Customer Settings"
const getCustomerSettings = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_CUSTOMER_SETTINGS];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
//ADD_FAVORITE = "Add Favorite",
const addFavorite = async (
  type: LookerContentType,
  id: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADD_FAVORITE];
  const queryParams: object = null;
  let body = {};
  if (type === LookerContentType.LOOK) {
    body = {
      look_id: id,
    };
  } else if (type === LookerContentType.DASHBOARD) {
    body = {
      dashboard_id: id,
    };
  }
  await runApiOp(operation, queryParams, body, setResult, setError);
};
//GET_FAVORITES = "Get Favorites",
const getFavorites = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_FAVORITES];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
//DELETE_FAVORITE = "Delete Favorite",
const deleteFavorite = async (
  favoriteId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_FAVORITE];
  operation = {
    ...operation,
    pathIdString: favoriteId,
  };
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// GET_ALERTS = "Get Alerts",
const getAlerts = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_ALERTS];
  const queryParams: object = null;
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// DELETE_ALERT = "Delete Alert",
const deleteAlert = async (
  alertId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_ALERT];
  operation = {
    ...operation,
    pathIdString: alertId,
  };
  const queryParams: object = null;

  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADMIN_GET_ALERTS = "Admin Get Alerts",
const getAlertsAdmin = async (
  username: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADMIN_GET_ALERTS];
  const queryParams: object = { username: encodeURIComponent(username) };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADMIN_DELETE_ALERT = "Admin Delete Alert",
const deleteAlertAdmin = async (
  alertId: string,
  username: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.ADMIN_DELETE_ALERT];
  operation = {
    ...operation,
    pathIdString: alertId,
  };
  const queryParams: object = { username: encodeURIComponent(username) };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// PRINTD_ACCESS_LOG = "Add to Print Designer Access Log"
const updatePrintDesignerAccessLog = async (
  accessMethod: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.PRINTD_ACCESS_LOG];
  const queryParams: object = { accessMethod: accessMethod };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
// ADMIN_APS_ALL_CUSTOM_DRUG_LISTS = "Get All Custom Drug Lists in the current Realm"
const getAllApsCustomDrugLists = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.ADMIN_APS_ALL_CUSTOM_DRUG_LISTS];
  await runApiOp(operation, null, null, setResult, setError);
};
// ADMIN_APS_CUSTOM_LIST_DETAILS = "Get Details of a Custom Drug List"
const getApsCustomDrugListDetails = async (
  listname: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.ADMIN_APS_CUSTOM_LIST_DETAILS];
  operation = {
    ...operation,
    pathIdString: listname,
  };
  await runApiOp(operation, null, null, setResult, setError);
};
//ADMIN_APS_DELETE_CUSTOM_LIST = "Delete a Custom Drug List"
const deleteApsCustomDrugList = async (
  listname: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.ADMIN_APS_DELETE_CUSTOM_LIST];
  operation = {
    ...operation,
    pathIdString: listname,
  };
  await runApiOp(operation, null, null, setResult, setError);
};
//ADMIN_APS_SAVE_VERIFY_CUSTOM_LIST = "Save and Verify a Custom Drug List"
const saveAndVerifyApsCustomDrugList = async (
  drugList: ICustomDrugList,
  setResult: (result: IDrugListSaveResult) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.ADMIN_APS_SAVE_VERIFY_CUSTOM_LIST];
  await runApiOp(operation, null, drugList, setResult, setError);
};
//GET_LOGIN_CONFIG =  "Get Login Configuration for Realm"
const getLoginConfigurationForRealm = async (
  realm: string,
  supportLogin: boolean,
  setResult: (result: IRealmLoginConfiguration) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_LOGIN_CONFIG];
  await runApiOp(operation, null, { realm: realm, supportLogin: supportLogin }, setResult, setError);
};
//GET_NOTIFICATIONS = "Get Notifications for the Current User"
const getNotifications = async (
  setResult: (result: INotification[]) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_NOTIFICATIONS];
  await runApiOp(operation, null, null, setResult, setError);
};
//GET_ALL_NOTIFICATIONS = "Get All Notifications for all Realms and Users"
const getAllNotifications = async (
  setResult: (result: INotification[]) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_ALL_NOTIFICATIONS];
  await runApiOp(operation, null, null, setResult, setError);
};
//SET_NOTIFICATION_STATUS = "Toggle Notification as Read or Unread"
const setNotificationStatus = async (
  notificationId: string,
  isRead: boolean,
  setResult: (result: IReadLogEntry) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.SET_NOTIFICATION_STATUS];
  operation = {
    ...operation,
    pathIdString: notificationId,
  };
  const queryParams: object = { isRead: isRead };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
//SET_NOTIFICATION_STATUS_BULK = "Toggle Notification as Read or Unread for Multiple Notifications"
const setNotificationStatusBulk = async (
  notificationIds: string[], // Adjusted the parameter to be an array
  isRead: boolean,
  setResult: (result: IReadLogEntry[]) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.SET_NOTIFICATION_STATUS_BULK];
  const queryParams: object = { isRead: isRead };

  // Assuming the runApiOp function accepts the request body as its third argument
  await runApiOp(operation, queryParams, notificationIds, setResult, setError);
};
//CREATE_NOTIFICATION = "Create a new Notification to be Pushed to Users"
const sendNotifications = async (
  notifications: INotification[],
  setResult: (result: INotification) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.CREATE_NOTIFICATION];
  await runApiOp(operation, null, notifications, setResult, setError);
};
//GET_REALMS = "Get Realms from Notifications API"
const getRealms = async (
  setResult: (result: string[]) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_REALMS];
  await runApiOp(operation, null, null, setResult, setError);
};
//GET_USERS_BY_REALMS = "Get Users by Realms from Notifications API"
const getUsersByRealms = async (
  realms: string[],
  setResult: (result: IRealmUser[]) => void,
  setError: (error: Error) => void
) => {
  type LoginUser = {
    loginRealm: string;
    loginUsername: string;
    realmUsername: string;
  };

  const getResult = (result: LoginUser[]) => {
    // Convert the result into an array of IRealmUser objects
    const realmUsers: IRealmUser[] = result.map((loginUser: LoginUser) => {
      return {
        realm: loginUser.loginRealm,
        username: loginUser.loginUsername,
        realmUsername: loginUser.realmUsername,
      };
    });

    setResult(realmUsers);
  };

  let operation = apiOperations[LookerApiOperation.GET_USERS_BY_REALMS];
  await runApiOp(operation, null, realms, getResult, setError);
};
//DELETE_NOTIFICATION = "Delete a Notification By ID"
export const deleteNotification = async (
  notificationId: string,
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.DELETE_NOTIFICATION];
  operation = {
    ...operation,
  };
  const queryParams: object = { notificationId: notificationId };
  await runApiOp(operation, queryParams, null, setResult, setError);
};
//EDIT_NOTIFICATION = "Edit a Notification By ID"
const editNotification = async (
  notification: INotification,
  setResult: (result: INotification) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.EDIT_NOTIFICATION];
  await runApiOp(operation, null, notification, setResult, setError);
};
//GET_DATA_LAST_REFRESH_DATETIME = "Get Data Last Refresh DateTime"
const getDataLastRefreshDateTime = async (
  setResult: (result: any) => void,
  setError: (error: Error) => void
) => {
  let operation =
    apiOperations[LookerApiOperation.GET_DATA_LAST_REFRESH_DATETIME];
  await runApiOp(operation, null, null, setResult, setError);
};
//  GET_LAST_ETL_COMPLETED = "Get Last Completed ETL Job"
const getLastEtlCompleted = async (
  setResult: (result: IETLRunStatus) => void,
  setError: (error: Error) => void
) => {
  let operation = apiOperations[LookerApiOperation.GET_LAST_ETL_COMPLETED];
  await runApiOp(operation, null, null, setResult, setError);
};

export const API = {
  // GET_CURRENT_USER = "Get Current User",
  getCurrentUser: getCurrentUser,
  // GET_SSO_EMBEDDED_URL = "Get SSO Embedded Url",
  getSsoEmbeddedUrl: getSsoEmbeddedUrl,
  // SEARCH_FOLDERS = "Search Folders",
  searchFoldersByName: searchFoldersByName,
  // SEARCH_DASHBOARDS = "Search Dashboards",
  searchDashboardsByName: searchDashboardsByName,
  // SEARCH_FOLDERS = "Search Folders",
  searchFolders: searchFolders,
  // GET_FOLDER = "Get Folder",
  getFolder: getFolder,
  // MOVE_FOLDER = "Move Folder",
  moveFolder: moveFolder,
  // RENAME_FOLDER = "Rename Folder",
  renameFolder: renameFolder,
  // GET_ALL_FOLDERS = "Get All Folders",
  getAllFolders: getAllFolders,
  // GET_FOLDER_CHILDREN = "Get Folder Childred",
  getFolderChildren: getFolderChildren,
  // SEARCH_FOLDER_CHILDREN = "Search Folder Children",
  searchFolderChildren: searchFolderChildren,
  // GET_FOLDER_PARENT = "Get Folder Parent",
  getFolderParent: getFolderParent,
  // GET_FOLDER_ANCESTORS = "Get Folder Ancestors",
  getFolderAncestors: getFolderAncestors,
  // GET_FOLDER_LOOKS = "Get Folder Looks",
  getFolderLooks: getFolderLooks,
  // GET_FOLDER_DASHBOARDS = "Get Folder Dashboards",
  getFolderDashboards: getFolderDashboards,
  // GET_ALL_LOOKS = "Get All Looks",
  getAllLooks: getAllLooks,
  // GET_LOOK = "Get Look",
  getLook: getLook,
  // MOVE_LOOK = "Move Look",
  moveLook: moveLook,
  // COPY_LOOK = "Copy Look",
  copyLook: copyLook,
  // GET_QUERY_FOR_SLUG = "Get Query For Slug"
  getQueryForSlug: getQueryForSlug,
  // GET_ALL_DASHBOARDS = "Get All Dashboards",
  getAllDashboards: getAllDashboards,
  // GET_DASHBOARD = "Get Dashboard",
  getDashboard: getDashboard,
  // GET_DASHBOARDE_ELEMENT = "Get Dashboard Element",
  getDashboardElement: getDashboardElement,
  // MOVE_DASHBOARD = "Move Dashboard",
  moveDashboard: moveDashboard,
  // COPY_DASHBOARD = "Copy Dashboard",
  copyDashboard: copyDashboard,
  // CREATE_FOLDER = "Create Folder",
  createFolder: createFolder,
  // CREATE_DASHBOARD = "Create Dashboard",
  createDashboard: createDashboard,
  // GET_CONTENT_THUMBNAIL = "Get Content Thumbnail",
  getContentThumbnail: getContentThumbnail,
  // GET_EMBED_USER = "Get Embed User",
  getEmbedUser: getEmbedUser,
  // DELETE_FOLDER = "Delete Folder",
  deleteFolder: deleteFolder,
  // DELETE_DASHBOARD = "Delete Dashboard",
  deleteDashboard: deleteDashboard,
  // DELETE_LOOK = "Delete Look",
  deleteLook: deleteLook,
  // SEARCH_CONTENT_VIEWS = "Search Content Views",
  searchContentViews: searchContentViews,
  // RECENTLY_RUN_REPORTS = "Recently Run Reports",
  recentlyRunReports: recentlyRunReports,
  // GROUP_USERNAMES = "Group Usernames",
  getGroupUsernames: getGroupUsernames,
  // SEARCH = "Search",
  search: search,
  // CREATE_STORE_GROUP = "Create Store Group",
  //createStoreGroup: createStoreGroup,
  // FIND_STORE_GROUP_BY_ID = "Find Store Group by Id",
  //findStoreGroupById: findStoreGroupById,
  // FIND_STORE_GROUPS_BY_FIELDS = "Find Store Groups by Fields",
  //findStoreGroupsByFields: findStoreGroupsByFields,
  // DELETE_STORE_GROUP_BY_ID = "Delete Store Group by Id",
  //deleteStoreGroupById: deleteStoreGroupById,
  // UPDATE_STORE_GROUP = "Update Store Group",
  //updateStoreGroup: updateStoreGroup,
  // GET_SCHEDULE_ACCESS = "Get Schedule Access",
  getScheduleAccess: getScheduleAccess,
  // GET_SCHEDULED_PLANS = "Get Scheduled Plans",
  getScheduledPlans: getScheduledPlans,
  // GET_RUN_ONCE_SCHEDULED_PLANS = "Get Run Once Scheduled Plans",
  getRunOnceScheduledPlans: getRunOnceScheduledPlans,
  // GET_CORPORATE_HIERARCHY = "Get Corporate Hierarchy",
  getCorporateHierarchy: getCorporateHierarchy,
  // DELETE_SCHEDULED_PLAN = "Delete Scheduled Plan",
  deleteScheduledPlan: deleteScheduledPlan,
  // RUN_SCHEDULE_ONCE_BY_ID = "Run Schedule Once by Id",
  runScheduleOnceById: runScheduleOnceById,
  // GET_CUSTOM_GROUPS = "Get Custom Groups",
  getCustomGroups: getCustomGroups,
  // GET_CUSTOM_GROUP_DETAILS = "Get Custom GroupDetails",
  getCustomGroupDetails: getCustomGroupDetails,
  // GET_LICENSED_USERS = "Get Licensed Users",
  getLicensedUsers: getLicensedUsers,
  // GET_LICENSE_INFO = "Get License Info",
  getLicenseInfo: getLicenseInfo,
  // GET_GROUP_DETAILS = "Get Group Details",
  getGroupDetails: getGroupDetails,
  // ADD_GROUP_TO_GROUP = "Add Group to Group",
  addGroupToGroup: addGroupToGroup,
  // REMOVE_GROUP_FROM_GROUP = "Remove Group from Group",
  removeGroupFromGroup: removeGroupFromGroup,
  // ADD_USER_TO_GROUP = "Add User to Group",
  addUserToGroup: addUserToGroup,
  // REMOVE_USER_FROM_GROUP = "Remove User from Group",
  removeUserFromGroup: removeUserFromGroup,
  // CREATE_GROUP = "Create Group",
  createGroup: createGroup,
  // DELETE_GROUP = "Delete Group",
  deleteGroup: deleteGroup,
  // FOLDER_PERMISSION_STATUS = "Folder Permission Status",
  getFolderPermissionStatus: getFolderPermissionStatus,
  // SET_FOLDER_PERMISSION = "Set Folder Permission",
  setFolderPermission: setFolderPermission,
  // ADMIN_GET_SCHEDULED_PLANS = "Admin Get Scheduled Plans",
  getScheduledPlansAdmin: getScheduledPlansAdmin,
  // ADMIN_DELETE_SCHEDULED_PLAN = "Admin Delete Scheduled Plan",
  deleteScheduledPlanAdmin: deleteScheduledPlanAdmin,
  // ADMIN_SET_DOWNLOAD = "Admin Set Download Permission",
  setDownloadPermission: setDownloadPermission,
  // ADMIN_SET_SCHEDULE = "Admin Set Schedule Permission",
  setSchedulePermission: setSchedulePermission,
  // SET_USER_HOLDS_LICENSE = "Set User Holds License",
  setUserHoldsLicense: setUserHoldsLicense,
  // DELETE_USER = "Delete User",
  deleteUser: deleteUser,
  // GET_FOLDER_EDIT_PERMISSIONS = "Get Folder Edit Permissions",
  getFolderEditPermissions: getFolderEditPermissions,
  // GET_REPORTS_DOCUMENTATION = "Get Documentation For Reports"
  getReportsDocumentation: getReportsDocumentation,
  // GET_DATA_MODEL_DOCUMENTATION = "Get Documentation For Data Models"
  getDataModelDocumentation: getDataModelDocumentation,
  // GET_EXPLORE_TILES_CONFIG = "Get Configuration Information for Explore Tiles"
  getExploreTilesConfig: getExploreTilesConfig,
  // GET_EXPLORE_DESCRIPTIONS = 'Get Explore Descriptions from Looker'
  getExploreDescriptions: getExploreDescriptions,
  // GET_APPLICATION_VERSION = 'Get Version Number for Application'
  getApplicationVersion: getApplicationVersion,
  //SET_REALM_HOME_PAGE = "Set the Home Page at the Realm Level"
  setUserHomePage: setUserHomePage,
  //SET_REALM_HOME_PAGE = "Set the Home Page at the Realm Level"
  setRealmHomePage: setRealmHomePage,
  // SET_GROUP_HOME_PAGE = "Set the Home Page for a user group"
  setGroupHomePage: setGroupHomePage,
  // GET_HOME_PAGE = "Get Home Page for Current User
  getHomePage: getHomePage,
  // GET_DEFAULT_HOME_PAGE = "Get Default Home Page for Current User
  getDefaultHomePage: getDefaultHomePage,
  // GET_LOOK_TITLE = "Get Title for Look given Look ID",
  getLookTitle: getLookTitle,
  // GET_DASHBOARD_TITLE = "Get Title for Dashboard given Dashboard ID",
  getDashboardTitle: getDashboardTitle,
  // GET_EXPLORE_TITLE = "Get Title for Explore given Model Name and Explore Name"
  getExploreTitle: getExploreTitle,
  // SET_PRINT_DESIGNER_ROLE = "Set the Print Designer Role for a Given User"
  setPrintDesignerRole: setPrintDesignerRole,
  // SCHEDULED_PLAN_LAST_RUN_STATUS = "Get the Last Run Status for a Given Scheduled Plan"
  getScheduleLastRunStatus: getScheduleLastRunStatus,
  // BULK_SCHEDULED_PLAN_LAST_RUN_STATUS = "Get the Last Run Status for a List of Scheduled Plans",
  getBulkScheduleLastRunStatus: getBulkScheduleLastRunStatus,
  // GET_STANDARD_REPORTS = "Get Standard Reports"
  getStandardReports: getStandardReports,
  // GET_CUSTOMER_SETTINGS = "Get Customer Settings"
  getCustomerSettings: getCustomerSettings,
  //ADD_FAVORITE = "Add Favorite"
  addFavorite: addFavorite,
  //GET_FAVORITES = "Get Favorites"
  getFavorites: getFavorites,
  //DELETE_FAVORITE = "Delete Favorite"
  deleteFavorite: deleteFavorite,
  // GET_ALERTS = "Get Alerts",
  getAlerts: getAlerts,
  // DELETE_ALERT = "Delete Alert",
  deleteAlert: deleteAlert,
  // ADMIN_GET_ALERTS = "Admin Get Alerts",
  getAlertsAdmin: getAlertsAdmin,
  // ADMIN_DELETE_ALERT = "Admin Delete Alert",
  deleteAlertAdmin: deleteAlertAdmin,
  // PRINTD_ACCESS_LOG = "Add to Print Designer Access Log"
  updatePrintDesignerAccessLog: updatePrintDesignerAccessLog,
  // ADMIN_APS_ALL_CUSTOM_DRUG_LISTS = "Get All Custom Drug Lists in the current Realm"
  getAllApsCustomDrugLists: getAllApsCustomDrugLists,
  // ADMIN_APS_CUSTOM_LIST_DETAILS = "Get Details of a Custom Drug List"
  getApsCustomDrugListDetails: getApsCustomDrugListDetails,
  //ADMIN_APS_DELETE_CUSTOM_LIST = "Delete a Custom Drug List"
  deleteApsCustomDrugList: deleteApsCustomDrugList,
  //ADMIN_APS_SAVE_VERIFY_CUSTOM_LIST = "Save and Verify a Custom Drug List"
  saveAndVerifyApsCustomDrugList: saveAndVerifyApsCustomDrugList,
  //GET_LOGIN_CONFIG =  "Get Login Configuration for Realm"
  getLoginConfigurationForRealm: getLoginConfigurationForRealm,
  //GET_NOTIFICATIONS = "Get Notifications for the Current User"
  getNotifications: getNotifications,
  //SET_NOTIFICATION_STATUS = "Toggle Notification as Read or Unread"
  setNotificationStatus: setNotificationStatus,
  //SET_NOTIFICATION_STATUS_BULK = "Toggle Notification as Read or Unread for Multiple Notifications"
  setNotificationStatusBulk: setNotificationStatusBulk,
  //CREATE_NOTIFICATION = "Create a new Notification to be Pushed to Users"
  sendNotifications: sendNotifications,
  //GET_REALMS = "Get Realms from Notifications API"
  getRealms: getRealms,
  //GET_USERS_BY_REALMS = "Get Users by Realms from Notifications API"
  getUsersByRealms: getUsersByRealms,
  //GET_ALL_NOTIFICATIONS = "Get All Notifications for all Realms and Users"
  getAllNotifications: getAllNotifications,
  //DELETE_NOTIFICATION = "Delete a Notification By ID"
  deleteNotification: deleteNotification,
  //EDIT_NOTIFICATION = "Edit a Notification By ID"
  editNotification: editNotification,
  //GET_DATA_LAST_REFRESH_DATETIME = "Get Data Last Refresh DateTime"
  getDataLastRefreshDateTime: getDataLastRefreshDateTime,
  //GET_LAST_ETL_COMPLETED = "Get Last Completed ETL Job"
  getLastEtlCompleted: getLastEtlCompleted,
};
