import { Role } from "../../services/RoleManagement/RoleModels";

export enum UserAuthActionTypes {
  LOGIN = "USER_AUTH/LOGIN",
  LOGOUT = "USER_AUTH/LOGOUT",
  PROPAGATE_LOGIN = "USER_AUTH/PROPAGATE_LOGIN",
  PROPAGATE_LOGOUT = "USER_AUTH/PROPAGATE_LOGOUT",
  LOOKUP = "USER_AUTH/LOOKUP",
  OKTA_LOGIN = "USER_AUTH/OKTA_LOGIN",
  CPS_PREMIUM = "USER_AUTH/CPS_PREMIUM",
  SET_ERROR_FROM_OKTA = "USER_AUTH/SET_ERROR_FROM_OKTA"
}

export enum DATA_MART {
  APS = "apsmain"
}

export interface IUserOktaLoginAction {
  type: UserAuthActionTypes.OKTA_LOGIN;
  isLoggedIn: boolean;
  errorMessage: string;
  roleMapping: Map<Role, boolean>;
  models: string[];
  username: string;
  realm: string;
  expiration: number;
  isSupport: boolean;
  scopeOfControlUser: string;
  sudoUser: string;
  printDesignerRole: string;
  oktaLogin: boolean;
  federatedLogin: boolean;
  federatedPrompt: IFederatedPromptState;
  loginId: string;
}

export interface IUserLoginAction {
  type: UserAuthActionTypes.LOGIN;
  credentials: IUserLoginCredentials;
  isLoggedIn: boolean;
  errorMessage: string;
  roleMapping: Map<Role, boolean>;
  models: string[];
  username: string;
  realm: string;
  expiration: number;
  isSupport: boolean;
  scopeOfControlUser: string;
  sudoUser: string;
  printDesignerRole: string;
  oktaLogin: boolean;
  federatedLogin: boolean;
  loginId: string;
}

export interface IUserPropagateLoginAction {
  type: UserAuthActionTypes.PROPAGATE_LOGIN;
  isLoggedIn: boolean;
  errorMessage: string;
  roleMapping: Map<Role, boolean>;
  models: string[];
  username: string;
  realm: string;
  expiration: number;
  isSupport: boolean;
  scopeOfControlUser: string;
  sudoUser: string;
  printDesignerRole: string;
  oktaLogin: boolean;
  federatedLogin: boolean;
  loginId: string;
}

export interface IUserLookupAction {
  type: UserAuthActionTypes.LOOKUP;
  isLoggedIn: boolean;
  errorMessage: string;
  roleMapping: Map<Role, boolean>
  models: string[];
  username: string;
  realm: string;
  checkForUserComplete: boolean;
  expiration: number;
  isSupport: boolean;
  scopeOfControlUser: string;
  sudoUser: string;
  printDesignerRole: string;
  oktaLogin: boolean;
  federatedLogin: boolean;
  loginId: string;
}

export interface IUserLogoutAction {
  type: UserAuthActionTypes.LOGOUT;
  oktaLogout: boolean;
}

export interface IUserPropagateLogoutAction {
  type: UserAuthActionTypes.PROPAGATE_LOGOUT;
}

export interface IUserCpsPremiumAction {
  type: UserAuthActionTypes.CPS_PREMIUM,
  cpsPremiumEnabled: boolean,
}

export interface ISetOktaErrorMessage {
  type: UserAuthActionTypes.SET_ERROR_FROM_OKTA;
  errorMessage: string;
}

export type UserAuthActions =
  | IUserLoginAction
  | IUserLogoutAction
  | IUserLookupAction
  | IUserPropagateLogoutAction
  | IUserPropagateLoginAction
  | IUserOktaLoginAction
  | IUserCpsPremiumAction
  | ISetOktaErrorMessage

export interface IFederatedPromptState {
  existingCustomer: boolean;
  erxOpenAm: boolean; //Jan 2025 - means customer is on OpenAM OR Okta for ERx - just not federated. 
  errorMessage: string;
  realm: string;
  okta: boolean;
}

export interface IUserLoginCredentials {
  username?: string;
  password?: string;
  realm: string;
  support: boolean;
  selectedUser?: string;
  scopeOfControlOnly?: boolean; 
  forceFlag?: boolean;
}

export interface IRealmLoginConfiguration { 
  success: boolean;
  found: boolean;
  realm: string;
  clientId: string; 
  okta: boolean;
  openamSupportOverride: boolean;
  federatedRedirect: boolean;
  oktaSupportVerifyRealm: boolean;
}

export interface IFederatedPromptCredentials {
  existingUser: "yes" | "no";
  username: string;
  password: string;
  realm: string;
}

export interface IUserAuthState {
  readonly credentials: IUserLoginCredentials;
  readonly isLoggedIn: boolean;
  readonly checkForUserComplete: boolean;
  readonly username: string;
  readonly realm: string;
  readonly errorMessage: string;
  readonly authorizedAt: Date;
  readonly roleMapping: Map<Role, boolean>;
  readonly models: string[];
  readonly expiration: number;
  readonly isSupport: boolean;
  readonly scopeOfControlUser: string;
  readonly sudoUser: string;
  readonly printDesignerRole: string;
  readonly oktaLogin: boolean;
  readonly federatedLogin: boolean;
  readonly oktaLogout: boolean;
  readonly federatedPrompt: IFederatedPromptState;
  readonly cpsPremiumEnabled: boolean;
  readonly loginId:string;
}
